<template>
  <div class="wap">
    <div class="scrollwap">
      <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
        <div v-if="list.length > 0">
          <van-list v-model="loading2" :finished="finished" finished-text="没有更多了" @load="nextPage">
            <div v-for="(item, index) in list" :key="index" class="list-item">
              <div class="itemleft">
                <span> 订单号：{{ item.orderNo }}</span>
                <span class="itemtime"> {{ item.createAt }}</span>
              </div>
              <span v-if="item.status === '1'"> - {{ item.cardPoints }}{{ getPoint(item) }}</span>
              <span v-else> + {{ item.cardPoints }}{{ getPoint(item) }}</span>
            </div>
          </van-list>
        </div>
        <div class="empt" v-else>
        </div>
      </van-pull-refresh>
    </div>
  </div>
</template>

<script>
export default {
  name: '',
  data() {
    return {
      isLoading: false,
      loading2: false, // 加载下一页
      finished: false, // 是否加载完了
      refreshing: false,
      list: [],
      form: {
        cardId: '',
        current: 1,
        size: 20
      }
    }
  },
  created() { },
  components: {},
  methods: {
    getPoint(val) {
      if (val && val.ticketType === 'E3' && val.upgradeFlag === 'C1') {
        return '票'
      } else {
        return '点'
      }
    },
    onRefresh() {
      this.form.current = 1
      this.queryCardExpendByCardId()
    },
    nextPage() {
      if (this.list.length >= 20) {
        this.form.current += 1
        this.queryCardExpendByCardId()
      }
    },
    queryCardExpendByCardId() {
      this.$api
        .queryCardExpendByCardId(this.form)
        .then((res) => {
          if (res.code == 1) {
            this.$Toast(res.msg)
            return
          }

          if (this.form.current == 1) {
            this.list = res.data.records
          } else {
            this.list.push(...res.data.records)
          }
          if (this.form.current >= res.data.pages) {
            this.finished = true
          }
          this.loading2 = false
          this.isLoading = false
        })
        .catch((err) => { });
    },

  },
  mounted() {
    this.form.cardId = this.$route.query.cardId
    this.queryCardExpendByCardId()
  },
}

</script>
<style lang="scss" scoped>
.wap {
  height: 100vh;
  overflow: hidden;
  background-color: #F8F8F8;
}

.scrollwap {
  height: 100vh;
  overflow: scroll;
}

.itemleft {
  display: flex;
  flex-direction: column;
}

.itemtime {
  color: #666;
  font-size: 0.85rem;
  padding-top: 0.5rem;
}

.list-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 1rem;
  border-bottom: 1px solid #DDD;
  align-items: center;
}
</style>